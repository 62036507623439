import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './boxes.module.scss';
import { actions } from '../../../state';
import { Api } from '../../../api/api';
import {
  mapServiceTypesNames,
  mapDeviceTypes,
  mapServiceToDeviceType,
  mapDeviceStatusIcon,
  isProtocolMQTT,
} from '../../../utils';
import {
  Toggle,
  ButtonSimple,
  InputElement,
  WithSpinner,
  Icon,
} from '../../../components';
import RefreshButton from '../../../components/refreshButton/refreshButton';
import { addEditServicesActions } from '../../../state/addEditServices/addEditServices.actions';
import RemoveButton from '../../../components/removeButton/RemoveButton';

const Boxes = ({
  handleStateFromBoxes,
  devices,
  location,
  dispatch,
  locationData,
  requestError,
}) => {
  const addLocation = window.location.pathname;

  const [boxFields, setBoxFields] = useState(
    devices.length === 0
      ? [{ ip: null, status: 10, title: '1', type: 201 }]
      : devices
          .sort((a, b) => a.title.localeCompare(b.title))
          .map(box => ({
            id: box.id,
            ip: box.ip,
            status: box.status,
            title: box.title,
            type: box.type,
            phone: box.phone,
          }))
  );

  useEffect(() => {
    if (devices.length) {
      const formatted = devices
        .sort((a, b) => a.title.localeCompare(b.title))
        .map(box => ({
          id: box.id,
          ip: box.ip,
          status: box.status,
          title: box.title,
          type: box.type,
          phone: box.phone,
        }));
      setStatusField(formatted);
      setBoxFields(formatted);
    }
  }, [devices]);

  const [statusField, setStatusField] = useState(
    devices.length === 0
      ? [{ ip: null, status: 2000, title: '', type: 201 }]
      : boxFields
  );

  const [boxLoading, setBoxLoading] = useState(false);

  useEffect(() => {
    handleStateFromBoxes(boxFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const nextTitle = type => {
    const closeBoxList = boxFields.filter(
      box => box.type === 201 && box.deleted !== true
    );
    const openBoxList = boxFields.filter(
      box => box.type === 202 && box.deleted !== true
    );

    if (type === 201) {
      return `${closeBoxList.length + 1}`;
    } else {
      return `${openBoxList.length + 1}`;
    }
  };

  const handleBoxChange = (i, { target: { value } }) => {
    const values = [...boxFields];
    const Ipstatus = [...statusField];
    values[i].ip = value;
    Ipstatus[i].ip = value;
    if (location !== 'self_service_washing') {
      values[i].title = `${i + 1}`;
      values[i].type = mapServiceToDeviceType(location);
    }

    setBoxFields(values);
    setStatusField(Ipstatus);
  };

  const handlePhoneNumberChange = (i, { target: { value } }) => {
    const values = [...boxFields];
    values[i].phone = value.toString();

    setBoxFields(values);
    handleStateFromBoxes(values);
  };

  const handleRemoveItem = (i, id) => {
    let values = [...boxFields];

    let newValues = [];
    if (id) {
      values[i].deleted = true;
    } else {
      values = values.filter((v, index) => index !== i);
    }

    const withoutDeleted = values.filter(val => !val.deleted);
    const deletedItems = values.filter(val => val.deleted);

    if (location === 'self_service_washing') {
      let openTitle = 0;
      let closeTitle = 0;

      newValues = withoutDeleted.map(item => {
        if (item.type === 201) {
          closeTitle = closeTitle + 1;
        } else {
          openTitle = openTitle + 1;
        }

        return {
          ...item,
          title:
            item.type === 201 ? closeTitle.toString() : openTitle.toString(),
        };
      });
    } else {
      newValues = withoutDeleted.map((item, i) => ({
        ...item,
        title: `${i + 1}`,
      }));
    }
    setBoxFields([...newValues, ...deletedItems]);
    handleStateFromBoxes([...newValues, ...deletedItems]);
    setTimeout(() => {
      dispatch(actions.locations.addLocationsAction());
      setTimeout(() => {
        setBoxFields([...newValues]);
        handleStateFromBoxes([...newValues]);
      }, 100);
    }, 100);
  };

  const handleBoxTypeChange = (i, { target: { value } }) => {
    const withoutDeleted = boxFields.filter(val => !val.deleted);

    const closedBoxesLength = withoutDeleted.filter(box => box.type === 201)
      .length;
    const openBoxesLength = withoutDeleted.filter(box => box.type === 202)
      .length;

    const title = +value === 201 ? closedBoxesLength + 1 : openBoxesLength + 1;
    const newValues = boxFields.map((box, index) =>
      index === i ? { ...box, type: +value, title: title.toString() } : box
    );
    setBoxFields(newValues);
    handleStateFromBoxes(newValues);
  };

  const handleBoxAdd = () => {
    const withoutDeleted = boxFields.filter(val => !val.deleted);
    const deletedItems = boxFields.filter(val => val.deleted);

    if (location === 'self_service_washing') {
      setBoxFields([
        ...withoutDeleted,
        { ip: null, status: 10, title: nextTitle(201), type: 201 },
        ...deletedItems,
      ]);
    } else {
      setBoxFields([
        ...withoutDeleted,
        { ip: null, status: 10, title: '', type: 201 },
        ...deletedItems,
      ]);
    }

    setStatusField([...statusField, { ip: null, status: 2000 }]);
  };

  const handleBoxActiveDisactive = x => {
    let values = [...boxFields];

    const currStatus = values[x].status;
    if (currStatus === 11 || currStatus === 2) {
      values[x].status = 10;
    } else {
      values[x].status = 11;
    }

    setBoxFields(values);
    handleStateFromBoxes(values);
  };

  const checkBoxStatusOnBlur = i => {
    const changed = boxFields[i].ip !== devices[i].ip;

    if (boxLoading || !changed) {
      return;
    } else {
      checkBoxStatus(i);
    }
  };

  const checkBoxStatusOnToggle = (i, checked) => {
    if (boxLoading || !checked) {
      return;
    } else {
      checkBoxStatus(i);
    }
  };

  const checkBoxStatusOnBlurAdd = i => {
    if (boxLoading) {
      return;
    } else {
      checkBoxStatus(i);
    }
  };

  async function checkBoxStatus(x) {
    const Ipstatus = [...statusField];
    const currValue = Ipstatus[x].ip;
    Ipstatus[x] = { ip: currValue, status: 2000 };
    setStatusField(Ipstatus);
    try {
      setBoxLoading(true);
      const boxStatus = await Api.checkBoxStatus(currValue);
      Ipstatus[x].status = boxStatus.status;
      setStatusField(Ipstatus);
    } catch (error) {
      console.error(error);
    } finally {
      setBoxLoading(false);
    }
    handleStateFromBoxes(boxFields);
  }

  const downloadQrCode = (device, location) => {
    const getServiceName = () => {
      if (location === 'self_service_washing') {
        if (device.type === 201) {
          return 'Boksas';
        }
        return 'Atviras boksas';
      }
      return mapServiceTypesNames(location);
    };

    const open = {
      modalName: 'qrCodeModal',
      children: device.qr,
      desc: `${locationData.address}, ${
        locationData.city
      }, ${getServiceName()}, ${device.title}`,
      title: 'QR kodas',
    };
    dispatch(actions.modals.setModalVisible(open));
  };

  const isChecked = status => {
    if (status === 11 || status === 2) {
      return false;
    } else {
      return true;
    }
  };

  const generateTitle = (title, i, id) => {
    const number = title === '' ? i + 1 : parseInt(title);

    if (location === 'self_service_washing') {
      if (!id && boxFields.filter(val => !val.deleted).length === i + 1) {
        return (
          <div className={styles.boxSelectContainer}>
            <span>{number}</span>
            <select
              value={boxFields[i].type}
              onChange={e => handleBoxTypeChange(i, e)}
              className={styles.boxSelect}
            >
              <option value={201}>Boksas</option>
              <option value={202}>Atviras boksas</option>
            </select>
          </div>
        );
      } else {
        return <div>{`${number} ${mapDeviceTypes(boxFields[i].type)}`}</div>;
      }
    } else {
      return <div>{`${number} ${mapDeviceTypes(location)}`}</div>;
    }
  };

  const handleOnRefreshClick = id => {
    dispatch(addEditServicesActions.regenerateDeviceKeyAction(id));
  };

  return (
    <div className={styles.box}>
      <table className={styles.container}>
        <thead>
          <tr>
            <th>Pavadinimas</th>
            <th>IP</th>
            <th>Tel. nr.</th>
            {addLocation !== '/locations/add-location' &&
              locationData[location] && <th style={{ width: 50 }}>QR kodas</th>}
            <th style={{ width: 50 }}>Būsena</th>
            <th style={{ width: 50 }}>Atnaujinti raktą</th>
            <th style={{ width: 25 }} />
          </tr>
        </thead>
        <tbody>
          {(() => {
            let titleId = -1;
            return boxFields.map((field, idx) => {
              if (field.deleted) {
                return <></>;
              }
              titleId += 1;

              const isFieldMQTT = isProtocolMQTT(field.ip);
              const phoneFieldValidation = !isFieldMQTT
                ? requestError && !field.phone
                : requestError;

              return (
                <tr key={idx}>
                  <td className={styles.titleFont} style={{ width: 100 }}>
                    {generateTitle(field.title, titleId, field.id)}
                  </td>
                  <td>
                    <InputElement
                      type="text"
                      placeholder="IP adresas"
                      value={field.ip || ''}
                      invalid={requestError && !field.ip}
                      name="ip_address"
                      onChange={e => handleBoxChange(idx, e)}
                      onBlur={() => {
                        addLocation !== '/locations/add-location' &&
                        !!devices[idx]
                          ? checkBoxStatusOnBlur(idx)
                          : checkBoxStatusOnBlurAdd(idx);
                      }}
                      style={{ maxWidth: 500 }}
                    />
                    <img
                      src={mapDeviceStatusIcon(
                        statusField.find(el => el.id === field.id)
                          ? statusField.find(el => el.id === field.id).status
                          : 0
                      )}
                      alt=""
                    />
                  </td>
                  <td style={{ width: 130 }}>
                    <InputElement
                      type="text"
                      placeholder="+37000000000"
                      value={field.phone || ''}
                      invalid={phoneFieldValidation}
                      name="phone_number"
                      onChange={e => handlePhoneNumberChange(idx, e)}
                      onBlur={() => {
                        addLocation !== '/locations/add-location' &&
                        !!devices[idx]
                          ? checkBoxStatusOnBlur(idx)
                          : checkBoxStatusOnBlurAdd(idx);
                      }}
                      style={{ width: 130, margin: 0, right: 0 }}
                    />
                  </td>
                  {addLocation !== '/locations/add-location' &&
                    locationData[location] && (
                      <td style={{ width: 100 }}>
                        {locationData[location].devices[idx] && (
                          <span
                            className={styles.qrCodeSave}
                            onClick={() =>
                              downloadQrCode(
                                locationData[location].devices[idx],
                                location
                              )
                            }
                          >
                            Parsisiųsti
                          </span>
                        )}
                      </td>
                    )}
                  <td>
                    <Toggle
                      size="sm"
                      checked={isChecked(field.status)}
                      disabled={boxLoading}
                      onChange={() => {
                        if (boxLoading) {
                          return;
                        } else {
                          handleBoxActiveDisactive(idx);
                          checkBoxStatusOnToggle(idx, isChecked(field.status));
                        }
                      }}
                    />
                  </td>
                  <td>
                    {addLocation !== '/locations/add-location' &&
                      locationData[location] &&
                      !isFieldMQTT && (
                        <RefreshButton
                          onClick={() => handleOnRefreshClick(field.id)}
                        />
                      )}
                  </td>
                  <td style={{ width: 25 }}>
                    <RemoveButton
                      title="Pašalinti įrenginį"
                      desc="Ar tikrai norite pašalinti įrenginį?"
                      onClick={() => handleRemoveItem(idx, field.id)}
                    />
                  </td>
                </tr>
              );
            });
          })()}
        </tbody>
      </table>
      <WithSpinner loading={boxLoading}>
        <div className={styles.addBox}>
          <ButtonSimple type="add" onClick={() => handleBoxAdd()}>
            Pridėti
          </ButtonSimple>
        </div>
      </WithSpinner>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    locationData: state.locations.selectedLocation,
  };
};

Boxes.propTypes = {
  dispatch: PropTypes.func.isRequired,
  handleStateFromBoxes: PropTypes.func.isRequired,
  devices: PropTypes.array.isRequired,
  location: PropTypes.string.isRequired,
  locationData: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(Boxes);
