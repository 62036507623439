import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import servicesBoxStyles from './servicesBoxStyles.module.scss';
import { actions } from './../../../state';
import Boxes from './../boxes/boxes';
import { InputElement } from './../../../components';
import { formattedDevices } from '../locations.utils';

const SelfServices = ({ dispatch, data, requestError }) => {
  const [values, setValues] = useState({
    soackingOff:
      data !== null ? data.options.find(obj => obj.type === 101).price : '',
    soackingOffTime:
      data !== null ? data.options.find(obj => obj.type === 101).time : '',
    washing:
      data !== null ? data.options.find(obj => obj.type === 102).price : '',
    washingTime:
      data !== null ? data.options.find(obj => obj.type === 102).time : '',
    rinsing:
      data !== null ? data.options.find(obj => obj.type === 103).price : '',
    rinsingTime:
      data !== null ? data.options.find(obj => obj.type === 103).time : '',
    waxing:
      data !== null ? data.options.find(obj => obj.type === 104).price : '',
    waxingTime:
      data !== null ? data.options.find(obj => obj.type === 104).time : '',
    draining:
      data !== null ? data.options.find(obj => obj.type === 105).price : '',
    drainingTime:
      data !== null ? data.options.find(obj => obj.type === 105).time : '',
    time: data !== null ? data.time : '',
  });

  const [devices, setBoxesValues] = useState(data === null ? [] : data.devices);

  const handleInputChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });
  };

  useEffect(() => {
    const options = [
      {
        type: 101,
        price: +values.soackingOff,
        time: +values.soackingOffTime,
      },
      {
        type: 102,
        price: +values.washing,
        time: +values.washingTime,
      },
      {
        type: 103,
        price: +values.rinsing,
        time: +values.rinsingTime,
      },
      {
        type: 104,
        price: +values.waxing,
        time: +values.waxingTime,
      },
      {
        type: 105,
        price: +values.draining,
        time: +values.drainingTime,
      },
    ];

    const data = {
      options,
      devices: formattedDevices(devices),
      time: +values.time,
    };

    dispatch(actions.addEditServices.pushSelfServicesAction(data));
  }, [devices, values]);

  const handleStateFromBoxes = x => {
    setBoxesValues(x);
  };

  return (
    <div className={servicesBoxStyles.mainContainer}>
      <table className={servicesBoxStyles.container}>
        <thead>
          <tr>
            <th>Paslaugos Pavadinimas</th>
            <th>
              <div>Kaina centais su PVM</div>
              <div />
            </th>
            <th>Trukmė sekundėmis</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={servicesBoxStyles.pricePlanInput}>Atmirkymas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="soackingOff"
                type="number"
                onChange={handleInputChange}
                value={values.soackingOff}
                invalid={!values.soackingOff && requestError}
                placeholder="0"
                min="0"
                step="1"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="soackingOffTime"
                type="number"
                onChange={handleInputChange}
                value={values.soackingOffTime}
                invalid={!values.soackingOffTime && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td>
            {/* <td className={servicesBoxStyles.pricePlanInput} rowspan="5">
              <InputElement
                name="time"
                type="number"
                onChange={handleInputChange}
                value={values.time}
                invalid={!values.time && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td> */}
          </tr>
          <tr>
            <td>Plovimas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="washing"
                type="number"
                onChange={handleInputChange}
                value={values.washing}
                invalid={!values.washing && requestError}
                placeholder="0"
                min="0"
                step="1"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="washingTime"
                type="number"
                onChange={handleInputChange}
                value={values.washingTime}
                invalid={!values.washingTime && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td>
          </tr>
          <tr>
            <td>Skalavimas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="rinsing"
                type="number"
                onChange={handleInputChange}
                value={values.rinsing}
                invalid={!values.rinsing && requestError}
                placeholder="0"
                min="0"
                step="1"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="rinsingTime"
                type="number"
                onChange={handleInputChange}
                value={values.rinsingTime}
                invalid={!values.rinsingTime && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td>
          </tr>
          <tr>
            <td>Vaškavimas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="waxing"
                type="number"
                onChange={handleInputChange}
                value={values.waxing}
                invalid={!values.waxing && requestError}
                placeholder="0"
                min="0"
                step="1"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="waxingTime"
                type="number"
                onChange={handleInputChange}
                value={values.waxingTime}
                invalid={!values.waxingTime && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td>
          </tr>
          <tr>
            <td>Sausinimas</td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="draining"
                type="number"
                onChange={handleInputChange}
                value={values.draining}
                invalid={!values.draining && requestError}
                placeholder="0"
                min="0"
                step="1"
              />
            </td>
            <td className={servicesBoxStyles.pricePlanInput}>
              <InputElement
                name="drainingTime"
                type="number"
                onChange={handleInputChange}
                value={values.drainingTime}
                invalid={!values.drainingTime && requestError}
                placeholder="0"
                min="1"
                step="1"
              />
            </td>
          </tr>
        </tbody>
      </table>

      <Boxes
        handleStateFromBoxes={handleStateFromBoxes}
        devices={data === null ? [] : data.devices}
        location="self_service_washing"
        requestError={requestError}
      />
    </div>
  );
};

SelfServices.propTypes = {
  dispatch: PropTypes.func,
  data: PropTypes.shape({
    devices: PropTypes.array.isRequired,
    id: PropTypes.number.isRequired,
    options: PropTypes.array.isRequired,
    status: PropTypes.number.isRequired,
    type: PropTypes.number.isRequired,
  }),
};

export default connect()(SelfServices);
